<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="breadcrumb">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/lubricationRecord' }">润滑记录</el-breadcrumb-item>
          <el-breadcrumb-item>记录详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="form-box">
        <el-form :model="form" ref="ruleForm" label-width="100px">
          <div class="form-tips">
            <div class="box-card">
              <div class="box-card-head">
                <div>
                  <span class="alias">{{ row.alias }}</span>
                  <span>本次润滑结果：</span>
                </div>
                <div class="box-card-right">
                  <i class="icon el-icon-user"></i>
                  <span>{{ row.submitter ? row.submitter : '（无）' }}</span>
                  <span class="lines"></span>
                  <i class="icon el-icon-time"></i>
                  <span>{{ row.completeTime ? dayjs(row.completeTime).format('YYYY-MM-DD HH:mm:ss') : '（无）' }}</span>
                </div>
              </div>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="任务状态：">{{ row.taskStatus==1 ? '已完成' : row.taskStatus==2 ? '已遗漏' : '未知' }}</el-form-item>
                </el-col>
              </el-row>
            </div>
          </div>
          <div class="form-title">润滑信息</div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="润滑代号：" prop="name">{{ form.lubeBaseInfo.taskCode }}</el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="巡视点名称：" prop="name">{{ form.lubeBaseInfo.patrolName }}</el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="设施位置：" prop="name">{{ form.lubeBaseInfo.facilityName }}</el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="润滑周期：" prop="name">
                {{ form.lubeBaseInfo.cycle==0 ? '星期配置' : '自定义' }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="润滑人员：" prop="name">
                <span v-for="(item, index) in form.lubeBaseInfo.staffNames" :key="index">
                  {{ form.lubeBaseInfo.staffNames.length-1 > index ? item+'、' : item }}
                </span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="时间段：" prop="name">
                {{ form.lubeBaseInfo.startTime ? dateSecond(form.lubeBaseInfo.startTime) : '00:00:00' }} - {{ form.lubeBaseInfo.endTime ? dateSecond(form.lubeBaseInfo.endTime) : '00:00:00' }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="创建人：" prop="name">{{ form.lubeBaseInfo.creator ? form.lubeBaseInfo.creator : '（无）' }}</el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="创建时间：" prop="name">
                {{ form.lubeBaseInfo.createTime ? dayjs(form.lubeBaseInfo.createTime).format('YYYY-MM-DD'):'-' }}
              </el-form-item>
            </el-col>
          </el-row>
          <div class="form-title">设备信息</div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="设备名称：" prop="name">{{ form.deviceInfo.deviceName }}</el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="物料编码：" prop="name">{{ form.deviceInfo.materialsCode }}</el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="所属部门：" prop="name">{{ form.deviceInfo.departmentName ? form.deviceInfo.departmentName : '（无）' }}</el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="规格参数：" prop="name">{{ form.deviceInfo.specification }}</el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="设备类型：" prop="name">{{ form.deviceInfo.deviceTypeName }}</el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="投入使用日期：" prop="name" label-width="130px">
                {{ form.deviceInfo.applyTime ? dayjs(form.deviceInfo.applyTime).format('YYYY-MM-DD'):'' }}
              </el-form-item>
            </el-col>
          </el-row>
          <div class="form-title">润滑内容</div>
          <!-- <el-form-item
            v-for="item in form.lubeCheckContents"
            :key="item.id"
            label="检查内容："
            prop="name"
          >{{ item.content }}</el-form-item> -->
          <div v-for="(item, key) in form.lubeCheckContents" :key="key" class="config-box">
            <div class="title">润滑部位 {{key + 1}}</div>
            <div style="padding-left:20px;">
              <el-row :gutter="20">
                <el-col :span="6">
                  <el-form-item label="部位名称：" prop="name">
                    {{item.position?item.position:'（无）'}}
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="润滑点数：" prop="name">
                    {{item.points?item.points:'（无）'}}
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="6">
                  <el-form-item label="润滑油品牌：" prop="name" label-width="115px">
                    {{item.brand?item.brand:'（无）'}}
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="润滑油分类：" prop="name" label-width="115px">
                    {{item.classification?item.classification:'（无）'}}
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="润滑油等级：" prop="name" label-width="115px">
                    {{item.level?item.level:'（无）'}}
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="补加油用量：" prop="name" label-width="115px">
                    {{item.refuel ? item.refuel : 0}}kg
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="6">
                  <el-form-item label="换底油用量：" prop="name" label-width="115px">
                    {{item.replace ? item.replace : 0}}kg
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
            <el-form-item label="润滑方法" prop="name"></el-form-item>
            <div style="padding-left:20px;" v-for="(items,index) in item.lubriMethod" :key="index">
                <el-row :gutter="20">
                    <el-col>
                      <el-form-item :label="`${index+1}.方法内容：`" prop="name" label-width="115px">
                        {{items.content}}
                      </el-form-item>
                    </el-col>
                </el-row>
            </div>
          </div>
          <div class="form-title">润滑文字描述</div>
          <el-row :gutter="20">
            <el-col>
              <div class="work-desc">{{ form.description.description ? form.description.description : '（空）' }}</div>
            </el-col>
          </el-row>
          <div class="form-title">润滑工作图片</div>
          <div class="img-box" v-if="form.files.length>0">
            <el-image 
              class="images"
              v-for="(item,index) in form.files"
              :key="index"
              :src="item.fileUrl"
              :preview-src-list="[item.fileUrl]">
            </el-image>
            <!-- <img v-for="item in form.deviceArchivesImages" :key="item.id" :src="item.url" /> -->
          </div>
          <div v-else>
            <el-form-item label="" label-width="20px">（空）</el-form-item>
          </div>
          <div class="bottom-btn">
            <el-button type="primary" size="small" @click="$router.push('/lubricationRecord')">取消</el-button>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';

export default {
  data() {
    return {
      form: {
        lubeBaseInfo: {},
        deviceInfo: {},
        description: {}
      },
      row: {},
      loading: false,
    };
  },
  methods: {
    dayjs,
    dateSecond(val) {
      let s = val % 60
      let _s = val - s

      let m = (_s / 60) % 60

      let h = (_s / 60 - m) / 60
      return `${h>9 ? h:'0'+h}:${m>9 ? m:'0'+m}:${s>9 ? s:'0'+s}`
    },
    loadData() {
      this.loading = true
      this.$ajax.post("lubeRecordDetail", {
        id: this.row.taskDeviceId,
      }).then((res) => {
        sessionStorage.setItem("detail", true);
        this.form = res.data;
        this.loading = false
      });
    },
  },
  mounted() {
    if (this.$route.params.row == null) {
      this.$router.push('/lubricationRecord')
      return
    }
    this.row = this.$route.params.row
    this.loadData()
  }
};
</script>

<style lang="less" scoped>
.form-box {
  padding-top: 20px;
}
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.breadcrumb {
  padding: 15px 10px;
  .el-breadcrumb {
    font-size: 18px;
  }
}
.img-box {
  .images {
    overflow: hidden;
    background-color: #fff;
    border: 1px solid #c0ccda;
    border-radius: 6px;
    box-sizing: border-box;
    width: 148px;
    height: 148px;
    margin: 0 8px 8px 0;
    display: inline-block;
  }
}
.form-tips {
  margin-bottom: 20px;
  .box-card {
    background-color: #FAFAFA;
    padding: 20px 20px 0px;
    border-radius: 10px;
    .box-card-head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid #F4F4F4;
      .alias {
        margin-right: 10px;
        background-color: #e5f2ff;
        color: #409EFF;
        padding: 10px;
        border-radius: 5px;
      }
      .box-card-right {
        display: flex;
        align-items: center;
        .lines {
          display: inline-block;
          width: 1px;
          height: 10px;
          background: #d7d7d7;
          margin: 0 20px;
        }
        .icon {
          margin-right: 10px;
          color: #797979;
        }
      }
    }
  }
}
.work-desc{
  font-size: 14px;
  margin: 0 20px 20px;
}
.config-box {
  background-color: #FAFAFA;
  border-radius: 10px;
  margin: 20px 0;
  overflow: hidden;
  .title {
    background-color: #f3f3f3;
    font-size: 15px;
    padding: 10px 35px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
<style lang="less">
</style>
